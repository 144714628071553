#dashboard_btn{
    cursor: pointer;
}
#dashboard_btn .card-img-overlay{
    background-color: rgba(255, 255, 255, 0.6)
}
#dashboard_btn .card-img-overlay .card-title{
    font-size:large;
    color: black;
    font-weight: bold;
}

#dashboard_btn .card:hover{
    filter: brightness(0.9);
    border: black thick;

}
#dashboard_btn .card:active{
    filter: brightness(0.7);

}