#navigation{
    margin: 0 0 2% 0
}

.navbar-brand img{
    height: 50pt;
}


#userInfo{
    background-color: lightpink;
    padding: 0.25% 20px;
}

#profileImg{
    height: 50pt;
    width: auto;
    border-radius: 50%;
}

#navigation .profile{
    position: relative;
    border-radius: 50%;
}

#navigation .profile:active{
    filter: brightness(0.75);
}


#navigation .profile svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: none;
}

#navigation .profile .spinner-border{
    position: absolute;
    top: 25%;
    left: 25%;
    display: block;
    color: white;
    display: none;
}