#reimbForm_area .row:nth-child(even){
    background-color: #cfcfcf;
}
#reimbForm_area .row{
    margin: 5pt 0;
    padding: 10pt;
}

#reimbForm_area .form-group{
    margin: 5pt 0;
}

#submitBtn svg{
    height: 12pt;
    margin-right: 5pt;
}

#newRowBtn svg{
    height: 12pt;
    margin-right: 5pt;
}

#reimbForm_prompt{
    padding: 30px;
    font-size: 20px;
    background-color: lightsteelblue;
    border: thick black
}

#reimbForm_prompt svg{
    width: auto;
    color: green;
}

#errorPrompt{
    margin: 10px;
    background-color: pink;
    font-weight: bold;
}