#auth_page{
    display: flex;
    height: 100vh;
    background-color: rgb(192, 192, 192);
}

#credentials{
    margin:auto;
    width: 400pt;
    padding: 25px;
    background-color: white;
    box-shadow: 2px 2px 3px darkgrey;
}

#cred_logo{
    display: flex;
    width: 100%;

}
#cred_logo img{
    margin: auto;
    height: auto;
    width: 50%;
}

#cred_disclaimer{
    width: 100%;
}

#credentials button{
    width: 100%;
}

#credentials button svg{
    width: 5%;
    height: auto;
    margin: 0 5px;
}
